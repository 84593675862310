"use client";

import { useContext, useEffect } from "react";
import { AIModule } from "@/components/AIModule";
import { BannerWrapperSize } from "@/components/Banner/types";
import { TabsModule } from "@/components/TabsModule";
import { GridModule } from "@/components/GridModule";
import { HeroModule } from "@/components/HeroModule";
import { KeepItSimpleModule } from "@/components/KeepItSimpleModule";
import { MoreModule } from "@/components/MoreModule";
import { ThreeStepsModule } from "@/components/ThreeStepsModule";
import { I_HomePage } from "@/data/types/pages";
import { useWindowSize } from "@/hooks/useWindowsSize";
import { amplitudeTrackEvent } from "@/services/AmplitudeService/AmplitudeService";
import { LayoutContext } from "@/contexts/LayoutContext";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";


export const HomePageTemplate = ({
  data,
  amplitudeEvent,
}: {
  data: I_HomePage;
  amplitudeEvent?: string;
}) => {
  const { setIsQRLanding } = useContext(LayoutContext);
  const screenSize = useWindowSize();
  const { setQrSelected } = useContext(AmplitudeContext);
  const getWrapperSize = (
    isTablet: BannerWrapperSize,
    isNotTablet: BannerWrapperSize
  ) => {
    return screenSize?.isTablet ? isTablet : isNotTablet;
  };


  useEffect(() => {
    setQrSelected(undefined);
    amplitudeEvent && amplitudeTrackEvent(amplitudeEvent);
  }, []);

  return (
    <main>
      <HeroModule
        data={data.billboard}
        wrapperSize={getWrapperSize("sm", "lg")}
      />
      <ThreeStepsModule
        data={data.featured2}
        wrapperSize={getWrapperSize("xs", "md")}
      />
      <AIModule data={data.featuredAI} />
      <GridModule
        data={data.previewHome}
        wrapperSize={getWrapperSize("xs", "lg")}
        //  backgroundColor={data.previewHome.backgroundColor}
      />
      <KeepItSimpleModule
        data={data.banner}
        wrapperSize={getWrapperSize("sm", "lg")}
      />
      <TabsModule
        data={{
          title: data.faqsHome.title,
          faqsHomeTabs: data.faqsHomeTabs,
        }}
        wrapperSize="md"
        backgroundColor={data.faqsHome.backgroundColor}
        customClass={data.faqsHome.customClass}
      />
      <MoreModule data={data.banner2} wrapperSize="md" />
    </main>
  );
};
