"use client";
import React from "react";
import classNames from "classnames";
import { ReactSVG } from "react-svg";

import Banner from "@/components/Banner";
import { Link } from "../Link";
import { Typography } from "@/components/Typography";
import { CardList } from "@/components/CardList";

import { BannerWrapperSize } from "@/components/Banner/types";
import { bannerAdvantagesProps } from "@/data/types/home";

import "./styles.scss";

export const KeepItSimpleModule = ({
  data,
  wrapperSize,
  setSource,
}: {
  data: bannerAdvantagesProps;
  wrapperSize: BannerWrapperSize;
  setSource?: (event: string) => void;
}) => {
  const {
    List,
    ctaLabel,
    ctaLink,
    richtext,
    customClass,
    ctaLabelShort,
    amplitudeEvent,
  } = data;

  const classnames = classNames(`home-advantages`, {
    [`home-advantages--${customClass}`]: customClass,
  });

  return (
    <Banner big white wrapperSize={wrapperSize} customClass="banner-cards">
      <div className={classnames}>
        <div className="home-advantages--header">
          {richtext.map((item, i: number) => (
            <Typography
              key={i}
              Tag={item.tag}
              size={item.size}
              spacing={item.spacing}
            >
              {item.list
                ? item.list.map((e, i) => (
                    <li key={i}>
                      {
                        <span
                          dangerouslySetInnerHTML={{ __html: e["item"] }}
                        ></span>
                      }
                    </li>
                  ))
                : item.content}
            </Typography>
          ))}
          <Link
            href={ctaLink}
            className="bold-text large button cta-desktop"
            type="button"
            onClick={() => amplitudeEvent && setSource(amplitudeEvent)}
            nofollow
          >
            {ctaLabel}
          </Link>

          <Link
            href={ctaLink}
            className="bold-text large button cta-mobile"
            type="button"
            onClick={() => amplitudeEvent && setSource(amplitudeEvent)}
            nofollow
          >
            {ctaLabelShort || ctaLabel}
          </Link>
        </div>

        <div className="home-advantages--body">
          <CardList
            cardItems={List.map((item) => ({
              icon: (
                <ReactSVG className="button__icon" src={item.icon as string} />
              ),
              title: item.title,
              subtitle: item.subtitle,
            }))}
            showCarrousel={true}
          />
          <Link
            href={ctaLink}
            className="bold-text large button cta-desktop_"
            type="button"
            onClick={() => amplitudeEvent && setSource(amplitudeEvent)}
            style={{ marginTop: "12px" }}
            nofollow
          >
            {ctaLabelShort || ctaLabel}
          </Link>
        </div>
      </div>
    </Banner>
  );
};
