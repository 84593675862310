import React from 'react'
import Image from 'next/image'

interface NextImageProps {
    src: string;
    alt: string;
    width?: number | `${number}`;
    height?: number | `${number}`;
    size?: string;
    style?: React.CSSProperties;
    layout?: 'fixed' | 'intrinsic' | 'responsive' | 'fill';
    className?: string;
    quality?: number | `${number}`;
    title?: string;
  }


export const NextImage = ({
    src,
    width,
    height,
    alt,
    size,
    style,
    layout,
    className,
    title,
    quality,
}: NextImageProps) => {
  return (
    <Image
        className={className}
        src={src}
        width={width}
        height={height}
        alt={alt}
        sizes={size}
        style={style}
        layout={layout}
        quality={quality}
        title={title}
    />
  )
}
